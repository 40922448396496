import React, { Component } from 'react'
import firebase from './constants/firebase';
import i18n from 'i18n-js';
import logo from './logo.png';
import workspace1 from './workspace_1.png';
import workspace2 from './workspace_2.png';
import workspace3 from './workspace_3.png';
import workspace4 from './workspace_4.png';
import workspace5 from './workspace_5.png';
import workspace6 from './workspace_6.png';

class Shop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetching: false,
      step: 1,
      plans: [],
      email: '',
      name: '',
      address : {
        line1: '',
        city: '',
        postal_code: ''
      },
      voNumberValid: true
    }
  }

  componentDidMount = () => {
    const path = window.location.pathname.split('?vo=')[1]
    this.setState({
      voNumber: path
    })
  }

  generateUID =() => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c==='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  handleChange = (evt) => {
    evt.preventDefault()
    this.setState({[evt.target.name]: evt.target.value})
  }

  handleAddressChange = (evt) => {
    evt.preventDefault()
    const { address } = this.state
    address[evt.target.name] = evt.target.value
    this.setState({address})
  }

  handleCompanyChange = (evt) => {
    evt.preventDefault()
    const workspace = evt.target.value.replace(/ /g,'').replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase()
    const company = evt.target.value
    this.setState({company, workspace: `${workspace}-${this.generateUID()}`})
  }

  handleSubmit = async(evt) => {
    evt.preventDefault()
    this.setState({fetching: true})
    const { company, ust, email, address, project, firstname, lastname, phone, voNumber, workspace, userId } = this.state
    const db = firebase.firestore()
    const element = {
      email,
      address,
      firstname,
      lastname,
      project: project || '', 
      phone,
      userId,
      voNumber: voNumber || '',
      workspace: workspace,
      company,
      ust: ust || '',
      createdAt: new Date()
    }

    return db.collection(`registrations`).add(element)
    .then(() => {
      this.setState({ confirmation: true })
    })
    .catch((error) => {
      console.error("Error updating document: ", error)
    })
  }

  handleRegisterUser = (evt) => {
    evt.preventDefault()
    const { email, password, workspace } = this.state
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (userData) => {
        const user = await firebase.auth().currentUser
        user.updateProfile({
          displayName: workspace,
        });
        this.setState({ userId: user.uid })
        this.handleNextStep(evt)
      })
      .catch((error) => {
        console.log(error)
        let registerError = ""
        if (error.code = 'auth/email-already-in-use') {
          registerError = i18n.t('emailExists')
        } else if (error.code == 'auth/invalid-email') {
          registerError = i18n.t('invalidEmail')
        } else if (error.code == 'auth/weak-password') {
          registerError = i18n.t('passwordWeak')
        }
        this.setState({registerError: registerError})
      })
  }

  handleNextStep = (evt) => {
    evt.preventDefault()
    this.setState({ step: this.state.step + 1 })
  }

  handlePrevStep = (evt) => {
    evt.preventDefault()
    this.setState({ step: this.state.step - 1 })
  }

  handlePrevToStart = (evt) => {
    evt.preventDefault()
    this.setState({ step: 1, error: null })
  }

  renderSteps = () => {
    const { step, error, confirmation } = this.state

    if (error) {
      return this.renderError()
    }

    if (confirmation) {
      return this.renderConfirmation()
    }

    switch (step) {
      case 1:
        return this.renderCompany()
      case 2:
        return this.renderAdminUser()
      case 3:
        return this.renderFirstProject()
      case 4:
        return this.renderSummary()
      default:
        return this.renderCompany()
    }
  }

  renderCompany = () => {
    const { address, ust, company } = this.state
    const submittable = company && address.line1 && address.postal_code && address.city
    return ([
      <div key="1" className="innerLeft">
        <div className="content">
          <h2>{i18n.t('companyAddress')}</h2>
          <p>{i18n.t('companyAddressDesc')}</p>
          <br/>
          <form onSubmit={this.handleNextStep} autoComplete="off">
            <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />
            <label>{i18n.t('companyName')} *</label>
            <input value={company} placeholder="Deine Firma GmbH" type="text" name="company" onChange={this.handleCompanyChange} required />
            <label>{i18n.t('street')} *</label>
            <input value={address.line1} placeholder="Musterstraße 4" type="text" name="line1" onChange={this.handleAddressChange} required />
            <label>{i18n.t('zip')} *</label>
            <input value={address.postal_code} placeholder="29640" type="text" name="postal_code" onChange={this.handleAddressChange} required />
            <label>{i18n.t('city')} *</label>
            <input value={address.city} placeholder="Musterstadt" type="text" name="city" onChange={this.handleAddressChange} required />
            <label>{i18n.t('ust')}</label>
            <input value={ust} placeholder="USt-IdNr" type="text" name="ust" onChange={this.handleChange} />
            <button type="submit" disabled={!submittable} className="c-button">{i18n.t('next')}</button>
            <br/><br/><span>* {i18n.t('required')}</span>
          </form>
        </div>
      </div>,
      <div key="2" className="innerRight">
        <div className="content">
          <img src={workspace3} alt="smartwerker" />
        </div>
      </div>
    ])
  }

  renderAdminUser = () => {
    const { firstname, lastname, email, password, phone, registerError } = this.state
    const submittable = firstname && lastname && email && password && phone
    return ([
      <div key="1" className="innerLeft">
        <div className="content">
          <h2>{i18n.t('defineUser')}</h2>
          <p>{i18n.t('defineUserDesc')}</p>
          <br/>
          <form name="administrator_name_form_body" onSubmit={this.handleRegisterUser} autoComplete="off">
            <label>{i18n.t('firstname')} *</label>
            <input value={firstname} autoComplete="new-password" type="text" placeholder="Max" name="firstname" onChange={this.handleChange} required />
            <label>{i18n.t('lastname')} *</label>
            <input value={lastname} autoComplete="new-password" type="text" placeholder="Mustermann" name="lastname" onChange={this.handleChange} required />
            <label>{i18n.t('phone')} *</label>
            <input value={phone} placeholder="0519 123" type="text" name="phone" onChange={this.handleChange} required />
            <label>{i18n.t('email')} *</label>
            <input value={email} autoComplete="new-password" type="email" placeholder="deine@email.com" name="email" onChange={this.handleChange} required />
            <label>{i18n.t('password')} *</label>
            <input value={password} autoComplete="new-password" type="password" placeholder="***" name="password" onChange={this.handleChange} required minLength="8" />

            {registerError &&<p className="error">{registerError}</p>}
            <button type="submit" disabled={!submittable} className="c-button">{i18n.t('next')}</button>
          </form>
          <br/><br/><span>* {i18n.t('required')}</span>
          <a href="#prev" className="prevLink" onClick={this.handlePrevStep}>{i18n.t('back')}</a>
        </div>
      </div>,
      <div key="2" className="innerRight">
        <div className="content">
          <img src={workspace2} alt="smartwerker" />
        </div>
      </div>
    ])
  }

  renderFirstProject = () => {
    return ([
      <div key="1" className="innerLeft">
        <div className="content">
          <h2>{i18n.t('firstProject')}</h2>
          <p>{i18n.t('firstProjectDesc')}</p>
          <br/>
          <form name="team_name_form_body">
            <input type="text" placeholder={i18n.t('firstProjectPlaceholder')} name="project" onChange={this.handleChange} />
            <button type="submit" className="c-button" onClick={this.handleNextStep}>{i18n.t('next')}</button>
          </form>
          <br/><br/><span>* {i18n.t('required')}</span>
          <a href="#prev" className="prevLink" onClick={this.handlePrevStep}>{i18n.t('back')}</a>
        </div>
      </div>,
      <div key="2" className="innerRight">
        <div className="content">
          <img src={workspace1} alt="smartwerker" />
        </div>
      </div>
    ])
  }

  renderSummary = () => {
    const { fetching, address, ust, company, email } = this.state
    return ([
      <div key="1" className="innerLeft">
        <div className="content">
          <h2>{i18n.t('summary')}</h2>
          <p>{i18n.t('summaryDesc')}</p>
          <br/>
          <form onSubmit={this.handleSubmit}>
            
            <div>
              <h3>{i18n.t('defineUser')}</h3>
              <p>{email}</p>
              <p>{i18n.t('hiddenPassword')}</p>

              <br/><br/>

              <h3>{i18n.t('companyAddress')}</h3>
              <p>
                {company && <span>{company}<br/></span>}
                {address.line1}<br/>
                {address.postal_code} {address.city}
                <br/><br/>
                {ust && <strong>{i18n.t('ust')}</strong>}
                <br/>
                {ust && <span>{ust}</span>}
              </p>
            </div>

            <br/><br/>

            {!fetching
              ? <button type="submit" className="c-button">{i18n.t('startForFree')}</button>
              : 
              <div>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                <p>{i18n.t('accountWillBeCreated')}</p>
              </div>
            }

            {!fetching &&
              <a href="#!" className="prevLink" onClick={this.handlePrevStep}>{i18n.t('back')}</a>
            }
          </form>
        </div>
      </div>,
      <div key="2" className="innerRight">
        <div className="content">
          <img src={workspace4} alt="smartwerker" />
        </div>
      </div>
    ])
  }

  renderError = () => {
    const { error } = this.state
    return ([
      <div key="1" className="innerLeft">
        <div className="content">
          <h2>{i18n.t('oops')}</h2>
          <p className="desc">
            {i18n.t('contactSupport')}<br/><br/>
            <strong>{i18n.t('errorDescription')} {error.error}</strong>
          </p>
          <a href="/kontakt" className="button c-button">{i18n.t('contactSupportBtn')}</a>
          <a href="#!" className="prevLink" onClick={this.handlePrevToStart}>{i18n.t('back')}</a>
        </div>
      </div>,
      <div key="2" className="innerRight">
        <div className="content">
          <img src={workspace5} alt="smartwerker" />
        </div>
      </div>
    ])
  }

  renderConfirmation = () => {
    return ([
      <div key="1" className="innerLeft">
        <div className="content">
          <h2>{i18n.t('companyRegisterSuccess')}</h2>
          <p className="desc">{i18n.t('companyRegisterSuccessDesc')}</p>
          <a href="https://web.smartwerker.app" className="button c-button">{i18n.t('startNow')}</a>
        </div>
      </div>,
      <div key="2" className="innerRight">
        <div className="content">
          <img src={workspace6} alt="smartwerker" />
        </div>
      </div>
    ])
  }

  render = () => {
    const { step } = this.state;

    return ([
      <header key="0">
        <a className="logo" href="/">
          <img src={logo} className="logo" alt="smartwerker" />
        </a>
      </header>,
      <main key="1">
        <div className="headlinWrapper">
          <h1 className="mainHeadline">{i18n.t('companyRegisterHeadline')}</h1>
          <span className="stepper">{i18n.t('step')} {step}/4</span>
        </div>
        <div className="wrapper">
          {this.renderSteps()}
        </div>
      </main>,
      <footer key="2">
        <a className="logo" href="/">
          <img src="https://smartwerker.app/wp-content/uploads/2019/08/landscape@2x-1.png" className="logo" alt="smartwerker" />
        </a>
        <ul id="bottom-menu">
          <li><a href="https://smartwerker.app/impressum/">{i18n.t('imprint')}</a></li> 
          <li><a href="https://smartwerker.app/datenschutzerklaerung/">{i18n.t('dataProtection')}</a></li> 
          <li><a href="https://smartwerker.app/nutzungsvereinbarung/">{i18n.t('termsOfUse')}</a></li> 
        </ul>
      </footer>
    ])
  }
}

export default Shop
