import React from 'react'
import './i18n/i18n'
import Shop from './Shop'
import './App.css'

const App = () => {
  return (
    <Shop/>
  )
}

export default App
